<template>
  <v-bottom-sheet
    v-model="isModalActive"
    max-width="500"
    persistent
    hide-overlay
    :retain-focus="false"
    no-click-animation
  >
    <v-card>
      <template v-if="!isCustomize">
        <v-card-title class="headline">
          Nous respectons votre vie privée.
        </v-card-title>

        <v-card-text>
          Nous utilisons des cookies pour améliorer votre expérience de navigation, diffuser des publicités ou des contenus personnalisés et analyser notre trafic. En cliquant sur « Tout accepter », vous consentez à notre utilisation des cookies.
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            small
            @click="deniedConsent"
          >
            Tout refuser
          </v-btn>
          <v-btn
            text
            small
            @click="isCustomize = true"
          >
            Personnaliser
          </v-btn>
          <v-btn
            color="primary"
            small
            @click="grantConsent"
          >
            Tout accepter
          </v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-title class="headline">
          Personnaliser les cookies
        </v-card-title>
        <v-card-text>
          Vous pouvez personnaliser les cookies que vous souhaitez accepter ou refuser.
        </v-card-text>
        <v-card-text>
          <v-switch
            v-model="adStorage"
            label="Stockage des publicités"
            true-value="granted"
            false-value="denied"
          />
          <v-switch
            v-model="analyticsStorage"
            label="Stockage des analyses"
            true-value="granted"
            false-value="denied"
          />
          <v-switch
            v-model="adUserData"
            true-value="granted"
            false-value="denied"
            label="Données utilisateur pour les publicités"
          />
          <v-switch
            v-model="adPersonalization"
            true-value="granted"
            false-value="denied"
            label="Personnalisation des publicités"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            small
            @click="isCustomize = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            small
            @click="customizeConsent"
          >
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'GoogleConsent',
  data () {
    return {
      isModalActive: false,
      isCustomize: false,
      adStorage: 'denied',
      analyticsStorage: 'denied',
      adUserData: 'denied',
      adPersonalization: 'denied'
    }
  },
  mounted () {
    this.isModalActive = localStorage.getItem('userConsent') !== 'done'
    window.addEventListener('storage', this.handleStorageChange)
  },
  beforeDestroy () {
    window.removeEventListener('storage', this.handleStorageChange)
  },
  methods: {
    handleStorageChange (event) {
      if (event.key === 'userConsent') {
        this.isModalActive = event.newValue !== 'done'
      }
    },
    closeModal () {
      this.isModalActive = false
      localStorage.setItem('userConsent', 'done')
    },
    grantConsent () {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted'
      })
      this.closeModal()
    },
    customizeConsent () {
      window.gtag('consent', 'update', {
        ad_storage: this.adStorage,
        analytics_storage: this.analyticsStorage,
        ad_user_data: this.adUserData,
        ad_personalization: this.adPersonalization
      })
      this.closeModal()
    },
    deniedConsent () {
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied'
      })
      this.closeModal()
    }
  }
}
</script>
